/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.textvalues {
    ::placeholder {
        font-size: 12px; //sets placeholder font size
        padding-left: 5px;
    }
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #581845;
    opacity: 1;
    height: 40px;
}
.textAreavalues{
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #581845;
    opacity: 1;
    padding: 5px;
}

.header_md{
  color: #8A2BE2;
  font-size: 17px;
  font-style: italic;
}

.div_width45{
    background-color: aliceblue;
}


